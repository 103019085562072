var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag',[_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.editProduct({ empty: true })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("products.btn_create_new_product")))])],1)],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.products.length > 0)?[_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-mg table-hover table-sm"},[_c('v-thead',[_c('v-tr',[(false)?_c('v-th',[_vm._v(_vm._s(_vm.$t("products.tbl_hdr_status")))]):_vm._e(),_c('v-th',[_vm._v(_vm._s(_vm.$t( "products.tbl_hdr_economic_pno" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "products.tbl_hdr_invoice_text" )))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t( "products.tbl_hdr_copy_to_heading" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "products.tbl_hdr_internal_note" )))]),_c('v-th')],1)],1),_c('v-tbody',_vm._l((_vm.products),function(p){return _c('v-tr',{key:p.id},[(false)?_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"fa",class:{
                                                    'fa-check text-success':
                                                        p.status === 1,
                                                    'fa-times text-warning':
                                                        p.status !== 1
                                                }})]):_vm._e(),_c('v-td',[_vm._v(_vm._s(p.ec_product_name)+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t( "products.economic_product_number" ))+": "+_vm._s(p.ec_product)+" ")])]),_c('v-td',[_vm._v(_vm._s(p.invoice_text))]),_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"far",class:{
                                                    'fa-check text-success':
                                                        p.copy_to_heading,
                                                    'fa-times text-warning': !p.copy_to_heading
                                                }})]),_c('v-td',[(p.internal_note)?[_c('v-newlines',{attrs:{"text":p.internal_note}})]:[_vm._v("---")]],2),_c('v-td',[_c('i',{staticClass:"far fa-pencil fa-lg m-click",on:{"click":function($event){return _vm.editProduct(p)}}})])],1)}),1)],1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)]:[_c('div',{staticClass:"mx-5 alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("products.no_products_found"))+" ")])]],2)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showProductModal)?_c('v-product-form',{attrs:{"context":_vm.editContext},on:{"close":function($event){_vm.showProductModal = false},"close-refresh":_vm.closeRefresh}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }