<script>
import ProductService from "@/services/ProductService";
import VProductForm from "@/components/Forms/VProductForm";

export default {
    name: "VProducts",
    components: { VProductForm },
    data() {
        return {
            loading: true,
            showProductModal: false,
            editContext: { empty: true },
            products: [],
            meta: {}
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * Fetch main data
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                // Important
                params.product_type = "subscription";
                const { data } = await ProductService.GET(params);
                this.products = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("pe", e);
            }

            this.loading = false;
        },
        /**
         * Create/edit product
         * @param {*} ctx
         */
        editProduct(ctx) {
            this.editContext = ctx;
            this.showProductModal = true;
        },
        closeRefresh() {
            this.editContext = null;
            this.showProductModal = false;
            this.fetchData();
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    @click="editProduct({ empty: true })"
                    size="sm"
                    type="primary"
                    ><i class="fa fa-plus"></i>
                    {{ $t("products.btn_create_new_product") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="products.length > 0">
                            <div class="table-responsive">
                                <v-table
                                    class="table table-mg table-hover table-sm"
                                >
                                    <v-thead>
                                        <v-tr>
                                            <v-th v-if="false">{{
                                                $t("products.tbl_hdr_status")
                                            }}</v-th>
                                            <v-th>{{
                                                $t(
                                                    "products.tbl_hdr_economic_pno"
                                                )
                                            }}</v-th>
                                            <v-th>{{
                                                $t(
                                                    "products.tbl_hdr_invoice_text"
                                                )
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t(
                                                    "products.tbl_hdr_copy_to_heading"
                                                )
                                            }}</v-th>
                                            <v-th>{{
                                                $t(
                                                    "products.tbl_hdr_internal_note"
                                                )
                                            }}</v-th>
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="p in products" :key="p.id">
                                            <v-td
                                                class="text-center"
                                                v-if="false"
                                            >
                                                <i
                                                    class="fa"
                                                    :class="{
                                                        'fa-check text-success':
                                                            p.status === 1,
                                                        'fa-times text-warning':
                                                            p.status !== 1
                                                    }"
                                                ></i>
                                            </v-td>
                                            <v-td
                                                >{{ p.ec_product_name }}
                                                <div class="small text-muted">
                                                    {{
                                                        $t(
                                                            "products.economic_product_number"
                                                        )
                                                    }}: {{ p.ec_product }}
                                                </div></v-td
                                            >
                                            <v-td>{{ p.invoice_text }}</v-td>
                                            <v-td class="text-center">
                                                <i
                                                    class="far"
                                                    :class="{
                                                        'fa-check text-success':
                                                            p.copy_to_heading,
                                                        'fa-times text-warning': !p.copy_to_heading
                                                    }"
                                                ></i
                                            ></v-td>
                                            <v-td>
                                                <template v-if="p.internal_note"
                                                    ><v-newlines
                                                        :text="p.internal_note"
                                                    />
                                                </template>
                                                <template v-else
                                                    >---</template
                                                ></v-td
                                            >
                                            <v-td
                                                ><i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="editProduct(p)"
                                                ></i
                                            ></v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="mx-5 alert alert-warning">
                                {{ $t("products.no_products_found") }}
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-product-form
                v-if="showProductModal"
                @close="showProductModal = false"
                @close-refresh="closeRefresh"
                :context="editContext"
            />
        </portal>
    </div>
</template>
