var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5"},[_c('FormulateInput',{attrs:{"type":"select","options":_vm.products,"label":_vm.$t('subscription.product'),"validation-name":_vm.$t('subscription.product'),"placeholder":_vm.$t('subscription.select_product'),"validation":"required"},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1)])]),(_vm.loadingData)?_c('v-loader',{attrs:{"loading":_vm.loadingData}}):(_vm.subscriptions.length > 0)?_c('card',[_c('v-table',{staticClass:"table table-sm table-mg table-striped"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t( "subscriptions.mass_tbl_hdr_id_and_customer" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("subscriptions.mass_tbl_hdr_amount")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "subscriptions.mass_tbl_hdr_from_date" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("subscriptions.mass_tbl_hdr_to_date")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "subscriptions.mass_tbl_hdr_product_and_invoice_header" )))])],1)],1),_c('v-tbody',_vm._l((_vm.subscriptions),function(s){return _c('v-tr',{key:s.id,class:{
                                    'entity-has-error': s.id in _vm.errors
                                }},[_c('v-td',[_vm._v("#"+_vm._s(s.id)+" "),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(s.customer.name)+" ")]),(s.id in _vm.errors)?_c('div',{staticClass:"alert alert-danger mt-2"},[('amount' in _vm.errors[s.id])?_c('div',[_vm._v(" "+_vm._s(_vm.$t( "subscriptions.vd_amount" ))+": "+_vm._s(Object.values( _vm.errors[s.id]["amount"] ).join(". "))+" ")]):_vm._e(),(
                                                'start_date' in _vm.errors[s.id]
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t( "subscriptions.vd_start_date" ))+": "+_vm._s(Object.values( _vm.errors[s.id][ "start_date" ] ).join(". "))+" ")]):_vm._e(),(
                                                'product_id' in _vm.errors[s.id]
                                            )?_c('div',[_vm._v(" "+_vm._s(_vm.$t( "subscriptions.vd_product_id" ))+": "+_vm._s(Object.values( _vm.errors[s.id][ "product_id" ] ).join(". "))+" ")]):_vm._e()]):_vm._e()]),_c('v-td',{staticStyle:{"width":"190px"}},[_c('FormulateInput',{attrs:{"type":"currency","currency":s.currency,"number-style":"money","validation-name":_vm.$t('subscription.amount'),"placeholder":_vm.$t(
                                                'subscription.provide_amount'
                                            ),"validation":"required"},model:{value:(s.amount),callback:function ($$v) {_vm.$set(s, "amount", $$v)},expression:"s.amount"}})],1),_c('v-td',[_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$d( new Date(s.start_date), "short" ))+" ")])]),_c('v-td',[_c('FormulateInput',{attrs:{"type":"date","validation-name":_vm.$t('subscription.start_date'),"validation":"required"},model:{value:(s.stop_date),callback:function ($$v) {_vm.$set(s, "stop_date", $$v)},expression:"s.stop_date"}})],1),_c('v-td',{staticStyle:{"width":"400px"}},[_c('FormulateInput',{attrs:{"type":"select","options":_vm.products,"validation-name":_vm.$t('subscription.product'),"placeholder":_vm.$t(
                                                'subscription.select_product'
                                            ),"validation":"required"},model:{value:(s.product_id),callback:function ($$v) {_vm.$set(s, "product_id", $$v)},expression:"s.product_id"}}),_c('FormulateInput',{attrs:{"label":_vm.$t('subscription.invoice_heading'),"type":"textarea"},model:{value:(s.invoice_heading),callback:function ($$v) {_vm.$set(s, "invoice_heading", $$v)},expression:"s.invoice_heading"}})],1)],1)}),1)],1),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 text-center"},[_c('hr'),('master' in _vm.errors)?_c('div',{staticClass:"alert alert-danger mt-3 mb-4"},[_vm._v(" "+_vm._s(_vm.errors.master)+" ")]):_vm._e(),_c('base-button',{attrs:{"size":"lg","type":"primary"},on:{"click":_vm.massEdit}},[_vm._v(_vm._s(_vm.$t("subscriptions.save_data")))])],1)]),(_vm.savingData)?_c('v-loader-modal',{attrs:{"loading":_vm.savingData,"name":_vm.$t('subscriptions.updating_subscription_data')}}):_vm._e(),(false)?_c('pre',[_vm._v(_vm._s(_vm.subscriptions))]):_vm._e()],1):(_vm.subscriptions.length == 0 && _vm.product)?[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t( "subscriptions.no_subscriptions_connected_to_this_product_found" ))+" ")])]:_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }