<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Cloner from "@/libraries/Cloner";
import EcService from "@/services/EcService";
import MGConsts from "@/mixins/MGConsts";
import ProductService from "@/services/ProductService";
import SVars from "@/components/Settings/SVars";
import SettingService from "@/services/SettingService";

export default {
    name: "VProductForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    mixins: [MGConsts],
    components: { SVars },
    data() {
        return {
            vars: null,
            editing: false,
            loading: true,
            errors: null,
            ec_products: [],
            product: {
                id: null,
                product_type: null,
                ec_product: null,
                invoice_text: null,
                invoice_text_en: null,
                internal_note: null,
                status: null,
                copy_to_heading: null,
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getIsAdmin"])
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchSettings();
        await this.fetchEcProducts();

        if (!this.context.empty) {
            // Edit
            this.editing = true;
            this.product = Cloner.pickClone(this.context, [
                "id",
                "created",
                "modified",
                "product_type",
                "ec_product",
                "invoice_text",
                "invoice_text_en",
                "internal_note",
                "status",
                "copy_to_heading"
            ]);
        } else {
            // Create
            this.product.status = "1";
            this.product.product_type = "subscription";
        }

        this.loading = false;
    },
    methods: {
        /**
         * Fetch settings data + vars
         */
        async fetchSettings() {
            try {
                const r2 = await SettingService.getNamespaceVariables(
                    "invoice_templates"
                );
                this.vars = r2.data.data;
            } catch (e) {}
        },
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await ProductService.PUT(this.product);
                } else {
                    const r = await ProductService.POST(this.product);
                }

                this.$notify({
                    message: this.$t("product.notification_new_product_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Delete
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("travel.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await ProductService.DELETE(
                                this.product.id
                            );
                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.errors = e.response.data.errors;
                                // this.$swal({
                                //     icon: "error",
                                //     title: this.$t("error_cannot_delete"),
                                //     text: e.response.data.errors.join(".\n"),
                                // });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        },
        /**
         * Get EC products
         */
        async fetchEcProducts() {
            try {
                const r = await EcService.ecProducts();
                this.ec_products = r.data.data;
            } catch (e) {}
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("product.edit") }}
            </template>
            <template v-else>
                {{ $t("product.add") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="product"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-12" v-if="false">
                        <FormulateInput
                            type="fselect"
                            :options="mgGeneralActive"
                            name="status"
                            :placeholder="$t('product.placeholder_status')"
                            validation="required"
                            :validation-name="$t('product.status')"
                            :help="$t('product.help_status')"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            type="fselect"
                            :options="ec_products"
                            name="ec_product"
                            :placeholder="$t('product.placeholder_ec_product')"
                            validation="required"
                            :validation-name="$t('product.ec_product')"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            name="invoice_text"
                            type="textarea"
                            :label="$t('product.invoice_text_danish')"
                            :help="$t('product.help_invoice_text')"
                        />

                        <FormulateInput
                            name="invoice_text_en"
                            type="textarea"
                            :label="$t('product.invoice_text_english')"
                            :help="$t('product.help_invoice_text')"
                        />
                        <s-vars :vars="vars.subscription_invoice_line" />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            type="fswitch"
                            name="copy_to_heading"
                            :label="$t('product.copy_to_heading')"
                            :validation-name="$t('product.copy_to_heading')"
                            :help="$t('product.help_copy_to_heading')"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            name="internal_note"
                            type="textarea"
                            :label="$t('product.internal_note')"
                            :help="$t('product.help_internal_note')"
                        />
                    </div>
                </div>

                <template v-if="false">
                    <div class="row">
                        <div
                            class="col-12 col-md-6 travel-billable text-center"
                        >
                            <FormulateInput
                                type="fswitch"
                                name="is_private_car"
                                :label="$t('travel.is_private_car')"
                            />
                        </div>
                        <div class="col-12">
                            <FormulateInput
                                type="fselect"
                                :options="customers"
                                name="customer_id"
                                :placeholder="$t('travel.placeholder_customer')"
                                validation="required"
                                :validation-name="$t('travel.customer')"
                                :disabled="parseInt(context.is_exported) === 1"
                            />
                        </div>

                        <div class="col-12">
                            <FormulateInput
                                :disabled="disabledProject"
                                type="fselect"
                                :options="projects"
                                name="project_id"
                                :no-options="$t('travel.no_cases_available')"
                                :placeholder="$t('travel.placeholder_project')"
                                :validation-name="$t('travel.case')"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                type="select"
                                :options="users"
                                :label="$t('travel.user')"
                                :placeholder="$t('travel.placeholder_user')"
                                :disabled="
                                    !ACL_RW ||
                                        parseInt(context.is_exported) === 1
                                "
                                name="user_id"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="date"
                                name="travel_date"
                                :label="$t('travel.travel_date')"
                                :validation-name="$t('travel.travel_date')"
                                validation="required"
                                :disabled="parseInt(context.is_exported) === 1"
                            />
                        </div>

                        <div class="col-12 col-md-6">
                            <FormulateInput
                                type="grouptext"
                                append="km"
                                name="kms"
                                :label="$t('travel.kilometres')"
                                :validation-name="$t('travel.kilometres')"
                                validation="required|number|min:0,value"
                                :disabled="parseInt(context.is_exported) === 1"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div
                            class="col-12 col-md-6 travel-billable text-center"
                        >
                            <FormulateInput
                                type="fswitch"
                                name="is_private_car"
                                :label="$t('travel.is_private_car')"
                                :validation-name="$t('travel.is_private_car')"
                                :disabled="
                                    parseInt(context.is_exported) === 1 ||
                                        !getIsAdmin
                                "
                            />
                        </div>
                        <div
                            class="col-12 col-md-6 travel-billable text-center"
                        >
                            <FormulateInput
                                type="fswitch"
                                name="is_billable"
                                :label="$t('travel.is_billable')"
                                :validation-name="$t('travel.is_billable')"
                                :disabled="parseInt(context.is_exported) === 1"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                type="textarea"
                                name="invoice_text"
                                :label="
                                    travel.is_billable
                                        ? $t('travel.invoice_text')
                                        : $t('travel.travel_description')
                                "
                                :validation-name="$t('travel.invoice_text')"
                                validation="required|max:255,length"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <FormulateInput
                                type="textarea"
                                name="notes"
                                :label="$t('travel.internal_notes')"
                                :validation-name="$t('travel.internal_notes')"
                            />
                        </div>
                    </div>
                </template>

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button
                            type="danger"
                            v-if="editing"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
