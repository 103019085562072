<script>
import ProductService from "@/services/ProductService";
import SubscriptionService from "@/services/SubscriptionService";

export default {
    name: "VMassEdit",
    data() {
        return {
            loading: true,
            loadingData: false,
            savingData: false,
            products: [],
            subscriptions: [],
            product: "",
            errors: []
        };
    },
    watch: {
        product: function(newValue, oldValue) {
            this.fetchData();
        }
    },
    created() {
        this.fetchProducts();
    },
    methods: {
        async massEdit() {
            try {
                this.savingData = true;
                const { data } = await SubscriptionService.PUT_massEdit(
                    this.subscriptions
                );
                
                if (data.data.status) {
                    // success
                    this.errors = [];
                    this.$notify({
                        message: this.$t(
                            "subscription.notification_subscription_data_successfully_updated"
                        ),
                        timeout: 8000,
                        icon: "far fa-check",
                        type: "success"
                    });
                    this.fetchData();
                } else {
                    this.$notify({
                        message: this.$t(
                            "subscription.notification_error_did_not_update_subscription_data_check_rows"
                        ),
                        timeout: 8000,
                        icon: "far fa-check",
                        type: "danger"
                    });
                    this.errors = data.data.errors;
                }
            } catch (e) {
                this.errors["master"] = this.$t(
                    "subscriptions.error_mass_edit_did_not_work_contact_support"
                );
            }

            this.savingData = false;
        },
        /**
         * Fetch subscriptions for editing
         */
        async fetchData() {
            try {
                this.loadingData = true;
                const params = { status: "1", product_id: this.product, subscription_type: 1 };
                const { data } = await SubscriptionService.GET(params);
                this.subscriptions = data.data;
            } catch (e) {
                console.log("ex", e);
            }

            this.loadingData = false;
        },
        /**
         * Fetch products -> dropdown
         */
        async fetchProducts() {
            try {
                const { data } = await ProductService.GET_dropdown(
                    "subscription"
                );
                this.products = data.data;
            } catch (e) {
                console.log("pe", e);
            }
            this.loading = false;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card>
                        <div class="row">
                            <div class="col-5">
                                <FormulateInput
                                    v-model="product"
                                    type="select"
                                    :options="products"
                                    :label="$t('subscription.product')"
                                    :validation-name="
                                        $t('subscription.product')
                                    "
                                    :placeholder="
                                        $t('subscription.select_product')
                                    "
                                    validation="required"
                                />
                            </div>
                        </div>
                    </card>

                    <v-loader :loading="loadingData" v-if="loadingData" />
                    <card v-else-if="subscriptions.length > 0">
                        <v-table class="table table-sm table-mg table-striped">
                            <v-thead>
                                <v-tr>
                                    <v-th>{{
                                        $t(
                                            "subscriptions.mass_tbl_hdr_id_and_customer"
                                        )
                                    }}</v-th>
                                    <v-th>{{
                                        $t("subscriptions.mass_tbl_hdr_amount")
                                    }}</v-th>
                                    <v-th>{{
                                        $t(
                                            "subscriptions.mass_tbl_hdr_from_date"
                                        )
                                    }}</v-th>
                                    <v-th>{{
                                        $t("subscriptions.mass_tbl_hdr_to_date")
                                    }}</v-th>
                                    <v-th>{{
                                        $t(
                                            "subscriptions.mass_tbl_hdr_product_and_invoice_header"
                                        )
                                    }}</v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr
                                    v-for="s in subscriptions"
                                    :key="s.id"
                                    :class="{
                                        'entity-has-error': s.id in errors
                                    }"
                                >
                                    <v-td
                                        >#{{ s.id }}
                                        <div class="small">
                                            {{ s.customer.name }}
                                        </div>
                                        <div
                                            v-if="s.id in errors"
                                            class="alert alert-danger mt-2"
                                        >
                                            <div
                                                v-if="'amount' in errors[s.id]"
                                            >
                                                {{
                                                    $t(
                                                        "subscriptions.vd_amount"
                                                    )
                                                }}:
                                                {{
                                                    Object.values(
                                                        errors[s.id]["amount"]
                                                    ).join(". ")
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    'start_date' in errors[s.id]
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "subscriptions.vd_start_date"
                                                    )
                                                }}:
                                                {{
                                                    Object.values(
                                                        errors[s.id][
                                                            "start_date"
                                                        ]
                                                    ).join(". ")
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    'product_id' in errors[s.id]
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "subscriptions.vd_product_id"
                                                    )
                                                }}:
                                                {{
                                                    Object.values(
                                                        errors[s.id][
                                                            "product_id"
                                                        ]
                                                    ).join(". ")
                                                }}
                                            </div>
                                        </div>
                                    </v-td>
                                    <v-td style="width: 190px">
                                        <FormulateInput
                                            v-model="s.amount"
                                            type="currency"
                                            :currency="s.currency"
                                            number-style="money"
                                            :validation-name="
                                                $t('subscription.amount')
                                            "
                                            :placeholder="
                                                $t(
                                                    'subscription.provide_amount'
                                                )
                                            "
                                            validation="required"
                                    /></v-td>

                                    <v-td>
                                        <div class="mt-2">
                                            {{
                                                $d(
                                                    new Date(s.start_date),
                                                    "short"
                                                )
                                            }}
                                        </div>
                                    </v-td>
                                    <v-td>
                                        <FormulateInput
                                            v-model="s.stop_date"
                                            type="date"
                                            :validation-name="
                                                $t('subscription.start_date')
                                            "
                                            validation="required"
                                        />
                                    </v-td>
                                    <v-td style="width: 400px">
                                        <FormulateInput
                                            v-model="s.product_id"
                                            type="select"
                                            :options="products"
                                            :validation-name="
                                                $t('subscription.product')
                                            "
                                            :placeholder="
                                                $t(
                                                    'subscription.select_product'
                                                )
                                            "
                                            validation="required"
                                        />
                                        <FormulateInput
                                            :label="$t('subscription.invoice_heading')"
                                            v-model="s.invoice_heading"
                                            type="textarea"
                                        />
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                        <div class="row mt-4">
                            <div class="col-12 text-center">
                                <hr />

                                <div
                                    class="alert alert-danger mt-3 mb-4"
                                    v-if="'master' in errors"
                                >
                                    {{ errors.master }}
                                </div>
                                <base-button
                                    size="lg"
                                    type="primary"
                                    @click="massEdit"
                                    >{{
                                        $t("subscriptions.save_data")
                                    }}</base-button
                                >
                            </div>
                        </div>

                        <v-loader-modal
                            :loading="savingData"
                            :name="
                                $t('subscriptions.updating_subscription_data')
                            "
                            v-if="savingData"
                        />

                        <pre v-if="false">{{ subscriptions }}</pre>
                    </card>
                    <template v-else-if="subscriptions.length == 0 && product">
                        <div class="alert alert-warning">
                            {{
                                $t(
                                    "subscriptions.no_subscriptions_connected_to_this_product_found"
                                )
                            }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.entity-has-error {
    border: 2px solid $danger !important;
}
</style>
