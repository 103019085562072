<script>
import CustomerService from "@/services/CustomerService";
import SubscriptionService from "@/services/SubscriptionService";
import VFilters from "@/components/Search/VFilters";
import MGConsts from "@/mixins/MGConsts";
import VSubscriptionForm from "@/components/Forms/VSubscriptionForm";
import VProduct from "@/components/Momentum/Subscriptions/VProduct";

export default {
    name: "VIndex",
    components: { VFilters, VSubscriptionForm, VProduct },
    mixins: [MGConsts],
    data() {
        return {
            loading: true,
            subscriptions: [],
            meta: {},
            customers: [],
            showSubscriptionModal: false,
            editContext: null,
            contextCustomerId: null
        };
    },
    computed: {
        statuses() {
            return {
                ...this.mgSubscriptionStatus,
                "0": this.$t("subscriptions.all")
            };
        }
    },
    async created() {
        this.apiPaginator.sort = "Customers.name";
        this.apiPaginator.direction = "asc";
        this.initSearch();
        await this.fetchCustomers(true);
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch main data, paginator friendly
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params.paginate = true;

                if (params.status === "0") {
                    delete params["status"];
                }
                const { data } = await SubscriptionService.GET(params);
                console.log(data);
                this.subscriptions = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("ex", e);
            }

            this.loading = false;
        },
        /**
         * Fetch customers for the dropdown
         * @param {} loading
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Init/reset search
         */
        initSearch() {
            this.apiSearchFilter = {
                customer_id: "",
                status: "1",
                currency: "",
                subscription_type: ""
            };
        },
        /**
         * Edit a subscription
         * @param {} ctx
         */
        editSubscription(ctx) {
            this.editContext = ctx;
            this.contextCustomerId = ctx.customer_id;
            this.showSubscriptionModal = true;
        },
        /**
         * Reload after saving the subs
         */
        closeSave() {
            this.editContext = null;
            this.contextCustomerId = null;
            this.fetchData();
            this.showSubscriptionModal = false;
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <a
                    class="btn btn-primary btn-sm"
                    href="/v1/subscriptions/download-csv"
                    ><i class="fa fa-file-csv"></i>
                    {{ $t("subscriptions.download_all_as_csv") }}</a
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        v-if="!loading"
                        :search="true"
                        v-model="apiSearchFilter.search"
                        :enable-filters="true"
                        :start-open="true"
                        @filter-view-change="$handleFilterView($event)"
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-4">
                                <FormulateInput
                                    v-model="apiSearchFilter.customer_id"
                                    :placeholder="$t('subscriptions.customer')"
                                    type="fselect"
                                    :options="customers"
                                />
                            </div>
                            <div class="col-4">
                                <FormulateInput
                                    v-model="apiSearchFilter.subscription_type"
                                    :placeholder="$t('subscriptions.type')"
                                    type="select"
                                    :options="mgSubscriptionType"
                                />
                            </div>
                            <div class="col-4">
                                <FormulateInput
                                    v-model="apiSearchFilter.status"
                                    :placeholder="$t('subscriptions.status')"
                                    type="select"
                                    :options="statuses"
                                />
                            </div>
                            <div class="col-4">
                                <FormulateInput
                                    v-model="apiSearchFilter.currency"
                                    :placeholder="$t('subscriptions.currency')"
                                    type="select"
                                    :options="mgCurrencies"
                                />
                            </div>
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="subscriptions.length > 0">
                            <div class="table-responsive">
                                <v-table
                                    class="table table-mg table-hover table-sm"
                                >
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("subscriptions.id")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Subscriptions.subscription_type"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("subscriptions.type")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.customer"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Products.invoice_text"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.product"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="start_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.start_date"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="stop_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.stop_date"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="frequency"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.frequency"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="amount"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.amount"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="currency"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.currency"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="status"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "subscriptions.status"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th> </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="s in subscriptions"
                                            :key="s.id"
                                        >
                                            <v-td>#{{ s.id }}</v-td>
                                            <v-td
                                                ><div class="small">
                                                    {{
                                                        s.subscription_type_name
                                                    }}
                                                </div>
                                                <div
                                                    class="small text-info "
                                                    v-if="
                                                        s.subscription_type == 2
                                                    "
                                                >
                                                    <i
                                                        class="fa fa-cart-plus"
                                                    ></i>
                                                    {{ s.unit_name }}
                                                </div>
                                            </v-td>
                                            <v-td
                                                ><router-link
                                                    class="small"
                                                    :to="{
                                                        name:
                                                            'CustomersTabSubscriptions',
                                                        params: {
                                                            customer_id:
                                                                s.customer_id
                                                        }
                                                    }"
                                                    >{{
                                                        s.customer.name
                                                    }}</router-link
                                                >
                                            </v-td>
                                            <v-td>
                                                <template
                                                    v-if="
                                                        s.subscription_type == 3
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            'subscription_lines' in
                                                                s
                                                        "
                                                        ><div
                                                            v-for="sl in s.subscription_lines"
                                                            :key="sl.id"
                                                        >
                                                            <v-product
                                                                :lang="
                                                                    s.customer
                                                                        .lang
                                                                "
                                                                :product="
                                                                    sl.product
                                                                "
                                                            />
                                                            <hr
                                                                class="mt-1 mb-2"
                                                            /></div
                                                    ></span>
                                                    <template v-else>
                                                        <span
                                                            class="text-danger"
                                                            >{{
                                                                $t(
                                                                    "invoice.sub_no_products_defined"
                                                                )
                                                            }}</span
                                                        >
                                                    </template>
                                                </template>
                                                <v-product
                                                    v-else
                                                    :lang="s.customer.lang"
                                                    :product="s.product"
                                                />
                                            </v-td>

                                            <v-td>{{
                                                $d(
                                                    new Date(s.start_date),
                                                    "short"
                                                )
                                            }}</v-td>
                                            <v-td>
                                                <template v-if="s.stop_date">
                                                    {{
                                                        $d(
                                                            new Date(
                                                                s.stop_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>---</template>
                                            </v-td>
                                            <v-td
                                                >{{ s.frequency }}
                                                <div class="small text-muted">
                                                    {{
                                                        s.payment_strategy_name
                                                    }}
                                                </div>
                                            </v-td>
                                            <v-td class="text-right">
                                                <template
                                                    v-if="
                                                        parseInt(
                                                            s.subscription_type
                                                        ) === 2
                                                    "
                                                    >---</template
                                                >
                                                <template v-else>
                                                    {{
                                                        $n(s.amount, "twodec")
                                                    }}</template
                                                ></v-td
                                            >
                                            <v-td>{{ s.currency }}</v-td>
                                            <v-td>{{ s.status_name }}</v-td>
                                            <v-td>
                                                <i
                                                    class="far fa-ban fa-lg"
                                                    v-if="s.status == 2"
                                                ></i>

                                                <i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="editSubscription(s)"
                                                    v-if="s.status == 1"
                                                ></i>

                                                <i
                                                    class="far fa-lg mt-2 fa-cog m-click"
                                                    v-if="
                                                        s.status == 1 &&
                                                            s.subscription_type !=
                                                                1
                                                    "
                                                    @click="
                                                        $router.push({
                                                            name:
                                                                'CustomersTabSubscriptionDetails',
                                                            params: {
                                                                subscription_id:
                                                                    s.id,
                                                                customer_id:
                                                                    s.customer_id
                                                            }
                                                        })
                                                    "
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="mx-5 alert alert-warning">
                                {{
                                    $t("subscriptions.not_subscriptions_found")
                                }}
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>

        <pre v-if="false">{{ subscriptions }}</pre>
        <portal to="modals">
            <v-subscription-form
                v-if="showSubscriptionModal"
                :context="editContext"
                :customer_id="contextCustomerId"
                :customer-name="editContext.customer.name"
                @close="showSubscriptionModal = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>
